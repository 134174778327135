import React from 'react'
import { Circles } from  'react-loader-spinner'
// import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import PropTypes from 'prop-types'
function Loader(props) {  
    const {hideMargin, size, color} = props

	return (
        <div className={`${!hideMargin && 'm-5 p-5'} loader d-flex justify-content-center align-items-center`}>
            <Circles
                height={size ? size : '40'}
                width={size ? size : '40'}
                color={color ? color : 'var(--bs-primary)'}
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </div>
        
        )
}
Loader.propTypes = {
	
	eventsLoader: PropTypes.bool,
    hideMargin: PropTypes.bool,
    size: PropTypes.string,
    color: PropTypes.string,
}

Loader.defaulProps = {
	
	eventsLoader: false,
    hideMargin: false,
    size: '',
    color: '',
	
}
export default Loader
