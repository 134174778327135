import CONSTANTS from '../constants'
import { EMPTY_OBJECT } from '../../constants/globalConstants'

const INITIAL_STATE = {
	loader: false,
	isUserLogin: false,
	isAuthenticated: false,
	hasError: false,
	data: EMPTY_OBJECT,
	sendEmailData: EMPTY_OBJECT,
	faclityData: EMPTY_OBJECT,
	email: null,
	role: null,
	errorMessage: '',
	id: null,
	orgId: ''
}

const singInAndSingUp = (state = INITIAL_STATE, action) => {
	switch (action.type) {

		//LOGIN HANDLING
		case CONSTANTS.LOGIN_REQUEST:
			return {
				...state,
				loader: true,
				errorMessage: ''
			}
		case CONSTANTS.LOGIN_SUCCESS:
			return {
				...state,
				loader: false,
				data: action.data,
				isAuthenticated: true,
				hasError: false,
				errorMessage: ''
			}
		case CONSTANTS.LOGIN_FAILURE:
			return {
				...state,
				loader: false,
				errorMessage: 'There is some Error'
			}

		// sing-up

		case CONSTANTS.SINGUP_REQUEST:
			return {
				...state,
				loader: true,
				errorMessage: ''
			}
		case CONSTANTS.SINGUP_SUCCESS:
			return {
				...state,
				loader: false,
				singup: action.data,
				isAuthenticated: true,
				hasError: false,
				errorMessage: ''
			}
		case CONSTANTS.SINGUP_FAILURE:
			return {
				...state,
				loader: false,
				errorMessage: 'There is some Error'
			}

		// send email reset password

		case CONSTANTS.SEND_EMAIL_RESET_PASSWORD_REQUEST:
			return {
				...state,
				loader: true,
				errorMessage: ''
			}
		case CONSTANTS.SEND_EMAIL_RESET_PASSWORD_SUCCESS:
			return {
				...state,
				loader: false,
				sendEmailData: action.data,
				isAuthenticated: true,
				hasError: false,
				errorMessage: ''
			}
		case CONSTANTS.SEND_EMAIL_RESET_PASSWORD_FAILURE:
			return {
				...state,
				loader: false,
				errorMessage: 'There is some Error'
			}

		//reset password

		case CONSTANTS.RESET_PASSWORD_REQUEST:
			return {
				...state,
				loader: true,
				errorMessage: ''
			}
		case CONSTANTS.RESET_PASSWORD_SUCCESS:
			return {
				...state,
				loader: false,
				sendEmailData: action.data,
				isAuthenticated: true,
				hasError: false,
				errorMessage: ''
			}
		case CONSTANTS.RESET_PASSWORD_FAILURE:
			return {
				...state,
				loader: false,
				errorMessage: 'There is some Error'
			}


		default:
			return state
	}
}

export default singInAndSingUp