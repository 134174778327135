import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import {ERROR_MESSAGE } from '../../constants/errorMessage'
import Footer from './Footer'
function NotFound() {


    
    return (
        <div className='main d-flex align-items-center justify-content-center'>
            <div>
                <h1 className='text-center text-danger'>
                    404<br/>{ERROR_MESSAGE?.NOT_FOUND}<br/>
                </h1>
            </div>
        </div>
    )
}

NotFound.propTypes = {
    dispatch: PropTypes.func,
    isFetchingPartner: PropTypes.bool,
}

NotFound.defaulProps = {
    dispatch: noop,
    isFetchingPartner: true,
}

function mapStateToProps({ userDetailsReducer }) {
    return {
        isFetchingPartner: userDetailsReducer?.isFetching
    }
}
export default connect(mapStateToProps)(NotFound)