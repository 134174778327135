
export const getConfigDetails = () => {

  // switch ('local') {
  switch ('live') {
    case 'local': 
      return {
        baseURL: 'http://localhost:80/pharmacedu-csm/api/index',
        adminUrl: 'https://demo-admin.parkengage.com',
        transientURL: 'https://demo-transient.parkengage.com',
    }

    case 'live': 
      return {
        baseURL: 'https://ldnilsurvey.com/api/index',
        adminUrl: 'https://partner.parkengage.com',
        checkInUrl: 'https://checkin.parkengage.com',
    }
    default:
      return {
        baseURL: 'http://localhost:80/wordpress/api/index',
        adminUrl: 'https://demo-admin.parkengage.com',
        transientURL: 'https://demo-transient.parkengage.com',
    }
  }
}
