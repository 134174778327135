import React from 'react'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { ERROR_MESSAGE } from '../../constants/errorMessage'
import REGEX_PATTERN from '../../constants/regex'
import { sendEmailResetPassword } from '../../redux/actions/singinAndSingUp'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/globalConstants'
import { convertJSONtoFormData } from '../../utils/helper'
import { useState } from 'react'
import { Circles } from 'react-loader-spinner'
import { InputGroup } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

function ResetPassword(props) {
    const {dispatch, notify} = props
    const { register, handleSubmit, formState: { errors }, watch } = useForm()
    const [loader, setLoader]= useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [CShowPassword, setCPasswordShown] = useState(false)
    const { token } = useParams()
    

    const handleResetPassword = (data) => {
        setLoader(true)
        const payload = {...data,
            apicall: 'resetpwd',
            key: token,
            usertype: 'admin'
        }
        const formData = convertJSONtoFormData(payload)
        dispatch(sendEmailResetPassword(formData)).then((res) => {
            setLoader(false)
            if(res && !res?.error){
                notify('success', res?.message ? res?.message : 'Password Updated, Please Login!')
            }else{
                notify('error', res?.message ? res?.message : 'Something went Wrong!')
            }
        }).catch((err) =>{
            setLoader(false)
            notify('error', err?.message ? err?.message : 'Something went Wrong!')
        })
    }

    const togglePasswordVisiblity = (e, key) => {
        if (key === 'password') { setShowPassword(!showPassword)}
        if (key === 'confirm_password') { setCPasswordShown(!CShowPassword)}
    }

	return (
		<div className='d-flex justify-content-center align-items-center m-5'>
            <div className='col-12 col-sm-6 border rounded p-3'>
                <p>Enter the new password below, keep these password with you for Logging in future.</p>
                <form autoComplete='on' className='d-flex flex-column' onSubmit={handleSubmit(handleResetPassword)}>
                <label className='fw-normal mt-2 mb-2'  htmlFor='email'>Email/Username <span className='text-danger'>*</span></label>
                        <InputGroup>
                            <input 
                                {...register('email', {
                                    required: { value: true, message: ERROR_MESSAGE?.REQ_ERROR_MSG },
                                    minLength: { value: 4, message: `${ERROR_MESSAGE?.MIN_ERROR_MSG} 4` },
                                    maxLength: { value: 40, message: `${ERROR_MESSAGE?.MAX_ERROR_MSG} 40` }
                                })}
                                className='form-control m-0'
                                type={'text'}
                                name='email'
                                id='email'
                                placeholder='Email/Username'
                            />
                        </InputGroup>
                        {(errors?.email) ? <span className='text-danger'>{errors?.email?.message}</span> : null}
                       
                        <label className='fw-normal mt-2 mb-2'  htmlFor='password'>Password <span className='text-danger'>*</span></label>
                        <InputGroup className='border rounded align-items-center d-flex'>
                            <input 
                                {...register('password', {
                                    required: { value: true, message: ERROR_MESSAGE?.REQ_ERROR_MSG },
                                    pattern: {
                                        value: REGEX_PATTERN.PASSWORD_FORMAT,
                                        message: ERROR_MESSAGE.PASSWORD_MESSAGE,
                                    },
                                })}
                                className='form-control m-0 border-0'
                                type={showPassword ? 'text' : 'password'}
                                name='password'
                                id='password'
                                placeholder='Password'
                            />
                            <i className={`${showPassword ? 'fas fa-eye-slash ' : 'fas fa-eye '} loginPassIcon me-1 ms-1`} onClick={(e) => togglePasswordVisiblity(e, 'password')}></i>
                        </InputGroup>
                        {(errors?.password) ? <span className='text-danger'>{errors?.password?.message}</span> : null}
                       
                        <label className='fw-normal mt-2 mb-2'  htmlFor='password'>Confirm Password <span className='text-danger'>*</span></label>
                        <InputGroup className='border rounded align-items-center d-flex'>
                            <input 
                                {...register('confirm_password', {
                                    required: { value: true, message: ERROR_MESSAGE?.REQ_ERROR_MSG },
                                    pattern: {
                                        value: REGEX_PATTERN.PASSWORD_FORMAT,
                                        message: ERROR_MESSAGE.PASSWORD_MESSAGE,
                                    },
                                    validate: (val) => {
                                        if (watch('password') != val) {
                                            return 'Passwords does not match'
                                        }
                                    },
                                })}
                                className='form-control m-0 border-0'
                                type={CShowPassword ? 'text' : 'password'}
                                name='confirm_password'
                                id='confirm_password'
                                placeholder='Confirm Password'
                            />
                            <i className={`${CShowPassword ? 'fas fa-eye-slash ' : 'fas fa-eye '} loginPassIcon me-1 ms-1`} onClick={(e) => togglePasswordVisiblity(e, 'confirm_password')}></i>
                        </InputGroup>
                        {(errors?.confirm_password) ? <span className='text-danger'>{errors?.confirm_password?.message}</span> : null}
                    <button 
                        type='submit'
                        name='submit'
                        disabled={loader}
                        className='mt-2 mb-2 btn btn-primary text-white'
                        >
                        <div className='d-flex justify-content-center align-items-center'>
                            <span>Reset Password </span>  <span className='ms-2'>
                                {(loader) ? <Circles
                                    height="20"
                                    width="20"
                                    style={{ display: 'inline-block' }}
                                    color="#ffffff"
                                    ariaLabel="circles-loading"
                                    visible={true}
                                /> : null}
                            </span>
                        </div>
                    </button>
                </form>
                
            </div>
            
		</div>
	)
}

ResetPassword.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    notify: PropTypes.func,
    isFetching: PropTypes.bool,
    partnerDetails: PropTypes.object,
}
  
ResetPassword.defaulProps = {
    dispatch: noop,
    loader: false,
    notify: noop,
    isFetching: true,
    partnerDetails: EMPTY_OBJECT,
}
function mapStateToProps({ singInAndSingUp, userDetailsReducer }) {
    return {
      loader: singInAndSingUp?.loader,
      isFetching: userDetailsReducer?.isFetching,
    }
}
export default connect(mapStateToProps)(ResetPassword)
