import React from 'react'
import dismigras from '../../assets/img/ldnil-logo.jpg'
import ldnil from '../../assets/img/dismigras-logo.jpg'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
function Footer(props) {
    const {displayLogo} = props
    const currentYear = new Date().getFullYear()
        
    return (
        <footer className='footer text-center d-flex align-items-center justify-content-center bg-dark'>
            {displayLogo ? <span className='copyrightText'>Powered by <img src={dismigras} alt="dismigras" width="100px" className='ps-2'/><img src={ldnil} alt="ldnil" width="100px" className='ps-2'/></span> : <span className='copyrightText'>&copy; {currentYear} LDnil - User Experience Survey</span>}
        </footer>
    )
}
Footer.propTypes = {
    
    displayLogo: PropTypes.bool,
}
  
Footer.defaulProps = {
    
    displayLogo: false
}


export default connect()(Footer)