import React from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import URL from '../constants/urls'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import NotFound from '../components/global/NotFound'
import { toast, ToastContainer } from 'react-toastify'
import useNetwork from '../Hooks/NetworkDetector'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../constants/globalConstants'
import Footer from '../components/global/Footer'
import Login from '../pages/loginSignup/Login'
import Header from '../components/global/Header'
import ForgotPassword from '../pages/loginSignup/ForgotPassword'
import 'react-toastify/dist/ReactToastify.css'
import ResetPassword from '../pages/loginSignup/ResetPassword'
import Thankyou from '../pages/ExamCenter/thankyou'
import { useEffect } from 'react'
import { useState } from 'react'
import Users from '../pages/admin/Users'
import Reports from '../pages/admin/Reports'

function PublicRoutes() {
    const isOnline = useNetwork()
    const [displayLogo, setDisplayLogo] = useState(false)
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    
    

    useEffect(() => {
        const location = window.location.href
        if(location == 'https://ldnilsurvey.com/' || location == 'https://ldnilsurvey.com/login' || location == 'https://ldnilsurvey.com/signup' || location == 'https://ldnilsurvey.com'){
            setDisplayLogo(true)
        }
    })

    const notify = (type, message) => {
        if (type === 'error') {
            toast.error(message, {
                position: toast.POSITION.TOP_RIGHT
            })
            toast.clearWaitingQueue()
        } else if (type === 'success') {
            toast.success(message, {
                position: toast.POSITION.TOP_RIGHT
            })
            toast.clearWaitingQueue()
        }
    }

    const DisplayToast = (text, title) => {
        return <div className='network-warning'>
            <span className='text-center fw-bold d-block'>{title}</span>
            <span className='text-center'>{text}</span>
        </div>
    }

   
    return (
        <div className='main'>
            <BrowserRouter forceRefresh>
                    <Header displayLogo={displayLogo} setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn}/>
                        <div className="content container m-auto" id="content">
                            {!isOnline ?
                                DisplayToast('Please check the Internet Connection!', 'Warning') 
                            : null}
                            <Routes>
                                <Route
                                    path={URL.INDEX}
                                    element={
                                        <Login notify={notify} setIsLoggedIn={setIsLoggedIn}/>
                                    }
                                />
                                <Route
                                    path={URL.DEFAULTPATH}
                                    element={
                                        <Login notify={notify} setIsLoggedIn={setIsLoggedIn}/>
                                    }
                                />
                                <Route
                                    path={URL.HOME}
                                    element={
                                        <Login notify={notify} setIsLoggedIn={setIsLoggedIn}/>
                                    }
                                />
                                <Route
                                    path={URL.LOGIN}
                                    element={
                                        <Login notify={notify} setIsLoggedIn={setIsLoggedIn}/>
                                    }
                                />
                                
                                
                                <Route
                                    path={URL.THANKYOU}
                                    element={
                                        <Thankyou notify={notify} isLoggedIn={isLoggedIn} />
                                    }
                                />
                                
                                {isLoggedIn ? <Route
                                    path={URL.USERS}
                                    element={
                                        <Users notify={notify} />
                                    }
                                /> : null}
                                {isLoggedIn ? <Route
                                    path={URL.REPORTS}
                                    element={
                                        <Reports notify={notify} />
                                    }
                                /> : null}
                                <Route
                                    path={URL.FORGOTPASSWORD}
                                    element={
                                        <ForgotPassword notify={notify} />
                                    }
                                />
                                <Route
                                    path={URL.RESETPASSWORD}
                                    element={
                                        <ResetPassword notify={notify} />
                                    }
                                />
                                <Route
                                    path={URL.INVALID}
                                    element={
                                        <NotFound notify={notify} />
                                    }
                                />
                            </Routes>
                        </div>
                    <Footer displayLogo={displayLogo}/>
            </BrowserRouter>
            <ToastContainer limit={1} />
        </div>
    )
}

PublicRoutes.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    isFetching: PropTypes.bool,
    cards: PropTypes?.array,
    notify: PropTypes?.func,
    isUserLogin: PropTypes?.bool,
}

PublicRoutes.defaulProps = {
    dispatch: noop,
    loader: true,
    isFetching: true,
    cards: EMPTY_ARRAY,
    notify: noop,
    isUserLogin: false,
}

function mapStateToProps({ gatedCheckinReducer, PaymentReducer, signInSignUpReducer, userDetailsReducer }) {
    return {
        loader: gatedCheckinReducer?.loader,
        cards: PaymentReducer?.data?.data?.payments,
        isUserLogin: signInSignUpReducer?.isUserLogin,
        isFetching: userDetailsReducer?.loader,

    }
}
export default connect(mapStateToProps)(PublicRoutes)