import React from 'react'
import { useNavigate } from 'react-router-dom'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/globalConstants'
import URL from '../../constants/urls'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { useEffect } from 'react'
import { useState } from 'react'
import {handleUserLogOut} from '../../redux/actions/singinAndSingUp'
import logo from '../../assets/img/msn-logo.jpg'
import { useContext } from 'react'

function Header(props) {
    const {dispatch, data, displayLogo, setIsLoggedIn, isLoggedIn} = props
    const navigate = useNavigate()
    const [userData, setUserData] = useState(EMPTY_OBJECT)
    const handleLoginRedirect = () => {
        navigate(`${URL?.LOGIN}`)
    }
    

    useEffect(() => {
        setUserData(data?.user)
        const userData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : EMPTY_OBJECT
        setUserData(userData)
        setIsLoggedIn(userData?.userid)
    },[EMPTY_ARRAY, isLoggedIn, JSON.stringify(data)])

    
    const handleLogout = () => {
        dispatch(handleUserLogOut())
        navigate('/login')
        setUserData(null)
        setIsLoggedIn(false)
    }

    return (
        <header className='header'>
            <div className='container m-auto d-flex align-items-center justify-content-between'>
                <span className="text-white text-decoration-none cursor p-2" onClick={() => handleLoginRedirect()}>
                        {displayLogo ? 
                            <>
                                <small><span className="text-white small d-block">This survey is brought to you by</span></small>
                                <img src={logo} alt="MSN" width="170px" />
                            </> : 'LDnil - User Experience Survey' }
                    </span>
                {userData && userData?.first_name && userData?.first_name != ''  ? <span className="text-white text-decoration-none cursor">
                    <span>Welcome, {userData?.first_name}</span> | <span onClick={() => handleLogout()}>Logout</span>
                </span> : <span className="text-white text-decoration-none cursor">
                    <span onClick={() => handleLoginRedirect()}>Login</span>
                </span>}
            </div>
        </header>
    )
}

Header.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    notify: PropTypes.func,
    isFetching: PropTypes.bool,
    partnerDetails: PropTypes.object,
    data: PropTypes.object,
    displayLogo: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    setIsLoggedIn: PropTypes.func,
}
  
Header.defaulProps = {
    dispatch: noop,
    loader: false,
    notify: noop,
    isFetching: true,
    partnerDetails: EMPTY_OBJECT,
    data: EMPTY_OBJECT,
    displayLogo: false,
    isLoggedIn: false,
    setIsLoggedIn: noop,
}

function mapStateToProps({ singInAndSingUp}) {
    return {
        loader: singInAndSingUp?.loader,
        data: singInAndSingUp?.data,
    }
}
export default connect(mapStateToProps)(Header)
