import React from 'react'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { ERROR_MESSAGE } from '../../constants/errorMessage'
import REGEX_PATTERN from '../../constants/regex'
import { sendEmailResetPassword } from '../../redux/actions/singinAndSingUp'
import { EMPTY_OBJECT } from '../../constants/globalConstants'
import { convertJSONtoFormData } from '../../utils/helper'
import { useState } from 'react'
import { Circles } from 'react-loader-spinner'

function ForgotPassword(props) {
    const {dispatch, notify} = props
    const { register, handleSubmit, formState: { errors }, reset } = useForm()
    const [loader, setLoader]= useState(false)

    const handleForgotPassword = (data) => {
        setLoader(true)

        const payload = {...data, apicall: 'forgotpwd', usertype: 'admin'}
        const formData = convertJSONtoFormData(payload)
        dispatch(sendEmailResetPassword(formData)).then((res) => {
            setLoader(false)
            if(res && !res?.error){
                notify('success', res?.message ? res?.message : 'Password Reset Link Sent!')
            }else{
                notify('error', res?.message ? res?.message : 'Something went Wrong!')
            }
        }).catch((err) =>{
            setLoader(false)
            notify('error', err?.message ? err?.message : 'Something went Wrong!')
        })
    }
	return (
		<div className='d-flex justify-content-center align-items-center m-5'>
            <div className='col-12 col-sm-6 border rounded p-3'>
                <p>Lost your password? Please enter your username or email address. You will receive a link to create a new password via email.</p>
                <form autoComplete='on' className='d-flex flex-column' onSubmit={handleSubmit(handleForgotPassword)}>
                    <label className='fw-normal mt-2 mb-2' htmlFor='username'>Username or Email <span className='text-danger'>*</span></label>
                    <input
                        {...register('username', {
                            required: { value: true, message: ERROR_MESSAGE?.REQ_ERROR_MSG },
                        })} 
                        className='form-control mt-2 mb-2'
                        type='text'
                        name='username'
                        id='username'
                        placeholder='Email or username'
                    />
                    {(errors?.username) ? <span className='text-danger'>{errors?.username?.message}</span> : null}
                    <button 
                        type='submit'
                        name='submit'
                        disabled={loader}
                        className='mt-2 mb-2 btn btn-primary text-white'
                        >
                        <div className='d-flex justify-content-center align-items-center'>
                            <span>Reset Password </span>  <span className='ms-2'>
                                {(loader) ? <Circles
                                    height="20"
                                    width="20"
                                    style={{ display: 'inline-block' }}
                                    color="#ffffff"
                                    ariaLabel="circles-loading"
                                    visible={true}
                                /> : null}
                            </span>
                        </div>
                    </button>
                    


                </form>
            </div>
            
		</div>
	)
}

ForgotPassword.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    notify: PropTypes.func,
    isFetching: PropTypes.bool,
    partnerDetails: PropTypes.object,
}
  
ForgotPassword.defaulProps = {
    dispatch: noop,
    loader: false,
    notify: noop,
    isFetching: true,
    partnerDetails: EMPTY_OBJECT,
}
function mapStateToProps({ singInAndSingUp, userDetailsReducer }) {
    return {
      loader: singInAndSingUp?.loader,
      isFetching: userDetailsReducer?.isFetching,
    }
}
export default connect(mapStateToProps)(ForgotPassword)
