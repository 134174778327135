const URL = {
	INDEX: '',
	DEFAULTPATH: '/',
	HOME: '/',
	LOGIN: '/login',
	SIGNUP: '/signup',
	FORGOTPASSWORD: '/forgot-password',
	RESETPASSWORD: '/reset-password/:token',
	EXAM: '/questionnaire',
	ADMIN: '/admin',
	THANKYOU: '/thank-you',
	USERS: '/users',
	REPORTS: '/download-report',
	USERS_RESPONSE: '/users-response',
	BASE: 'https://pharmacedu.com/',
	COURSES: 'https://pharmacedu.com/cluevo/lms/my-learning-tree/',
	MODULES: 'https://pharmacedu.com/cluevo/pages/index/',
	INVALID: '*',
}

export default URL
