import CONSTANTS from '../constants'
import { postRequest } from '../../services'
import { getConfigDetails } from '../../services/config'


// const buildHeaders = () => {
//     let headers = { 
//         'Content-Type': 'application/json',
//     }

//     return headers
// }
export const exportReport = (creds) => (dispatch) => {
    dispatch({ type: CONSTANTS.EXPORT_REPORT_REQUEST })
    return download(creds).then((res) => {
        if(res == 'err'){
            dispatch({ type: CONSTANTS.EXPORT_REPORT_FAILURE })
            return false
        }else{
            dispatch({ type: CONSTANTS.EXPORT_REPORT_SUCCESS, data: res })
            return true
        }
    }).catch((err) => {
      dispatch({ type: CONSTANTS.EXPORT_REPORT_FAILURE })
      return err
    })
}

export const download = (creds) => {
    const options = {
        method: 'post',
        // headers: { 
        //     'Content-Type': 'application/json',
        // },
        body: creds
    }
    return fetch(getConfigDetails()?.baseURL, options)
        .then(
            (res) => {
                if(res.status == 500){
                    return false
                }else{
                   return res.blob()
                }
            }
        )
        .then(
            (blob) => {
                if(blob.size > 0 && blob.type != 'application/json'){
                if (window.navigator && window.navigator.msSaveBlob) {
                        window.navigator.msSaveBlob(blob, 'export.xlsx')
                } else {
                        let a = document.createElement('a')
                        a.style = 'display: none'
                        a.href = window.URL.createObjectURL(blob)
                        a.download = 'export.xlsx'
                        document.body.appendChild(a)
                        a.click()
                        window.URL.revokeObjectURL(getConfigDetails()?.baseURL)
                        a.remove()
                        
                    }
                }else{
                    return 'err'
                }
            }
        ).catch(()=>{
            return 'err'
        })
}