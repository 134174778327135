import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { ERROR_MESSAGE } from '../../constants/errorMessage'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/globalConstants'
import REGEX_PATTERN from '../../constants/regex'
import URL from '../../constants/urls'
import { userSingIn } from '../../redux/actions/singinAndSingUp'
import { Circles } from 'react-loader-spinner'
import { useState } from 'react'
import { convertJSONtoFormData, encryptMailId } from '../../utils/helper'
import { exportReport } from '../../redux/actions/export&Download'
import { InputGroup } from 'react-bootstrap'
import { submitAnswer } from '../../redux/actions/submitanswer'
import { useEffect } from 'react'

function ThankYou(props) {
    const {dispatch, notify, isLoggedIn} = props
    const navigate = useNavigate()

    useEffect(() => {
        if(!isLoggedIn){
            navigate(URL?.LOGIN)
        }
    },[EMPTY_ARRAY, isLoggedIn])
	return (
        <div>
            <div className='d-flex flex-column align-items-center m-5'>
                <div className='w-100 text-start bg-grey m-2 p-2'>
                    <h3 className='w-100 fw-bold text-center text-success'>Thank You</h3><br/>
                    <p>Thank you for being a part of the examination, 
                        <span>
                            Your answers have been submitted, and result will be sent to your mail shortly. 
                            <br/><br/>You are requested to quit the session here. 
                            <br/><br/><br/>Thanks
                        </span>
                    </p>
                </div>

            </div>
        </div>
	)
}

ThankYou.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    notify: PropTypes.func,
    isFetching: PropTypes.bool,
    partnerDetails: PropTypes.object,
    isLoggedIn: PropTypes.bool,
}
  
ThankYou.defaulProps = {
    dispatch: noop,
    loader: false,
    notify: noop,
    isFetching: true,
    partnerDetails: EMPTY_OBJECT,
    isLoggedIn: false,
}

function mapStateToProps({ singInAndSingUp, userDetailsReducer }) {
    return {
        loader: singInAndSingUp?.loader,
        isFetching: userDetailsReducer?.isFetching,
    }
}
export default connect(mapStateToProps)(ThankYou)

