import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/globalConstants'
import URL from '../../constants/urls'
import { useState } from 'react'
import { convertJSONtoFormData, encryptMailId } from '../../utils/helper'
import { exportReport } from '../../redux/actions/export&Download'
import { useEffect } from 'react'
import { getUsers } from '../../redux/actions/commonAdmin'
import moment from 'moment'
import Loader from '../../components/Loader'
import { InputGroup } from 'react-bootstrap'

function Users(props) {
    const {dispatch, notify} = props
    const [loader, setLoader] = useState(true)
    const [isDownloading, setIsDownloading] = useState(false)
    const [userList, setUserList] = useState(EMPTY_ARRAY)
    const [admin, setAdmin] = useState(EMPTY_OBJECT)
    const [nextActive, setNextActive] = useState(false)
    const [previousActive, setPreviousActive] = useState(false)
    const [data, setData] = useState(EMPTY_OBJECT)
    const [page, setPage] = useState(1)
    const [searchValue, setSearchValue] = useState('')

    useEffect(() => {
        const admin = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : EMPTY_OBJECT
        setAdmin(admin)
        // CALL USER LIST API AND UPDATE USERS TABLE
        const payload = {apicall: 'users', username: admin?.username, page: 1}
        const formData = convertJSONtoFormData(payload)
        getPaginatedUsers(formData)
        setNextActive(true)
        setPreviousActive(false)
    },[EMPTY_ARRAY])

    const getPaginatedUsers = (formData) => {
        setLoader(true)

        dispatch(getUsers(formData)).then((res)=>{
            setData(res)
            if(res?.to >= res?.total){
                setNextActive(false)
            }else{
                setNextActive(true)
            }
            setLoader(false)
            if(res && res?.users){
                setUserList(res?.users)
            }else{
                notify('error', res?.message ? res?.message : 'Something went Wrong!')
            }
        }).catch((err) =>{
            setLoader(false)
            notify('error', err?.message ? err?.message : 'Something went Wrong!')
        })
    }

    const handleSearch = () => {
        if(searchValue && searchValue != ''){
            const payload = {apicall: 'users', username: admin?.username, page: 1, search: searchValue}
            const formData = convertJSONtoFormData(payload)
            getPaginatedUsers(formData)
        }
        
    }

    const handleDownload = (id) => {
        setIsDownloading(true)
        
        const payload = {apicall: 'exportreport', userid: id, startDate: '1990-04-01', endDate: moment(new Date()).format('YYYY-MM-DD')}
        const formData = convertJSONtoFormData(payload)
        if(!loader){
            dispatch(exportReport(formData)).then((res) => {
                setIsDownloading(false)
                if(res){
                    notify('success', res?.message ? res?.message : 'File Downloaded!')
                }else{
                    notify('error', res?.message ? res?.message : 'No Data Found!')
                }
            }).catch((err) =>{
                setIsDownloading(false)
                notify('error', err?.message ? err?.message : 'No Data Found!')
            })
        }
    }
    
    const handleOnChange = (event) => {
        const {value} = event.target
        setSearchValue(value)
    }
    
    const handlePagination = (action) => {
        if(action == 'next' && nextActive){
            const nextPage = page+1
            setPage(nextPage)
            const payload = {apicall: 'users', username: admin?.username, page: nextPage}
            const formData = convertJSONtoFormData(payload)
            getPaginatedUsers(formData)
            setPreviousActive(true)
        }else if(action == 'previous' && previousActive){
            const previousPage = page > 1 ? page-1 : page
            setPage(previousPage)
            const payload = {apicall: 'users', username: admin?.username, page: previousPage}
            const formData = convertJSONtoFormData(payload)
            getPaginatedUsers(formData)
            if(previousPage == 1){
                setPreviousActive(false)
            }else{
                setPreviousActive(true)
            }
        }
    }
    
	return (
        <div>
            
            <div className='d-flex flex-column align-items-center mt-5'>
                <div className='col-12 col-sm-12 border rounded p-3'>
                    <div className='fw-bold d-flex justify-content-between'>
                        <h3>Users</h3>
                    <div>
                        <button 
                            name="download"
                            type="button"
                            onClick={()=> handleDownload('all')}
                            disabled={isDownloading}
                            className="btn btn-primary">
                                Download All Reports
                        </button>
                        <span className='m-2 pointer text-decoration-underline'>
                            <Link to={URL?.REPORTS}>More</Link>
                        </span>
                    </div>
                    </div>
                    
                    <div className='border mt-4 d-flex justify-content-between align-items-center pe-2'>
                        
                        <InputGroup className='m-1 border rounded align-items-center d-flex input-group-search'>
                            <input 
                                type='text'
                                name='search'
                                className='m-0 border-0 form-control'
                                placeholder='Search by name'
                                onChange={(e) => handleOnChange(e)}
                            />
                            <button
                                type='button' 
                                className='ps-2 pe-2 m-0 btn btn-primary border-0'
                                onClick={() => handleSearch()}
                                >
                                <i className={'fas fa-search'}></i>
                            </button>
                            
                        </InputGroup>
                        <span>
                            
                            <button
                                type='button'
                                name='previous'
                                className='btn border rounded m-2 p-2'
                                disabled={!previousActive}
                                onClick={() => handlePagination('previous')}
                            >
                                <i className='fa fa-angle-left pe-2'></i>Previous
                            </button>
                            {page}
                            <button
                                type='button'
                                name='next'
                                className='btn border rounded m-2 p-2'
                                disabled={!nextActive}
                                onClick={() => handlePagination('next')}
                            >
                                Next<i className='fa fa-angle-right ps-2'></i>
                            </button>
                        </span>
                    </div>
                    {loader ? <Loader/>:

                    <div className='table-responsive'>
                        
                        <table id="users" className='table table-striped w-100'>
                            <thead>
                            <tr>
                                {/* <th>Sr. No.</th> */}
                                <th>Name</th>
                                <th>Email</th>
                                <th>Username</th>
                                <th>Phone</th>
                                <th>Country</th>
                                <th>Speciality</th>
                                <th>Hospital</th>
                                <th>Questionnaire</th>
                            
                            </tr>
                            </thead>
                            <tbody>
                            {userList?.map((user,index) => {
                                return (<tr key={index}>
                                {/* <td>{user?.id}</td> */}
                                <td>{`${user?.first_name} ${user?.last_name}`}</td>
                                <td>{user?.email}</td>
                                <td>{user?.username}</td>
                                <td>{user?.phone}</td>
                                <td>{user?.country}</td>
                                <td>{user?.speciality}</td>
                                <td>{user?.hospital}</td>
                                <td className='text-center'>{user?.examAttended == 1 ? <i className='fa fa-download' onClick={()=> handleDownload(user?.id)}></i> : null}</td>
                            </tr>)
                            })}
                        
                            </tbody>
                        </table>
                        {userList?.length < 1 ? <div className='p-2 text-center text-danger border rounded border-danger w-100'>{data?.message}</div> : null}
                    </div>
                    }
                    {userList?.length > 0 ? <div className='mt-4 d-flex justify-content-end align-items-center pe-2'>
                        
                        <span>
                            Showing {data?.from} to {data?.to} of {data?.total} Results
                        </span>
                    </div> : null}
                </div>
                
            </div> 
        </div>
	)
}

Users.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    notify: PropTypes.func,
    isFetching: PropTypes.bool,
    partnerDetails: PropTypes.object,
}
  
Users.defaulProps = {
    dispatch: noop,
    loader: false,
    notify: noop,
    isFetching: true,
    partnerDetails: EMPTY_OBJECT,
}

function mapStateToProps({ singInAndSingUp, userDetailsReducer }) {
    return {
        loader: singInAndSingUp?.loader,
        isFetching: userDetailsReducer?.isFetching,
    }
}
export default connect(mapStateToProps)(Users)

