import React, {useState} from 'react'
import { Controller, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { EMPTY_OBJECT } from '../../constants/globalConstants'
import { convertJSONtoFormData, encryptMailId } from '../../utils/helper'
import { exportReport } from '../../redux/actions/export&Download'
import moment from 'moment'
import DatePicker from 'react-date-picker'
import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'
import { Link } from 'react-router-dom'
import URL from '../../constants/urls'

// import { useLocalStorage } from '../../pages/base/LocalStorageContext'

function Report(props) {
    const {dispatch, notify} = props
    const [isDownloading, setIsDownloading] = useState(false)
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const { handleSubmit, control } = useForm()

    

    const handleOnChange = (date,field) => {
        console.log(date,field)
        if(field == 'startDate'){
            setStartDate(date)
        }else{
            setEndDate(date)
        }
    }
        
    const handleDownload = () => {
        setIsDownloading(true)
        
        const payload = {apicall: 'exportreport', userid: 'all', startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD')}
        const formData = convertJSONtoFormData(payload)
        if(!isDownloading){
            dispatch(exportReport(formData)).then((res) => {
                setIsDownloading(false)
                if(res){
                    notify('success', res?.message ? res?.message : 'File Downloaded!')
                }else{
                    notify('error', res?.message ? res?.message : 'No Data Found!')
                }
            }).catch((err) =>{
                setIsDownloading(false)
                notify('error', err?.message ? err?.message : 'No Data Found!')
            })
        }
    }
    
    
    
	return (
        <div>
            <div className="col-12 m-2 d-flex justify-content-between align-items-center">
                <span><Link to={URL?.USERS}>Home</Link> / Reports</span>
                <Link to={URL?.USERS}><span className='m-2 btn border btn-primary p-2'><i className='fa fa-angle-left pe-2'></i>Back
                </span></Link>
            </div>

            <div className='d-flex flex-column align-items-center mt-5'>
                <div className='col-12 col-sm-6 border rounded p-3'>
                    <div className='fw-bold d-flex justify-content-between'>
                        <h3>Download Report</h3>                   
                    </div>
                    <div className='row'>
                    <form autoComplete='on' className='d-flex flex-column' onSubmit={handleSubmit(handleDownload)}>
                        <div className='col-12 p-2'>
                            <label htmlFor="card_number">Start date</label>
                            <Controller
                                name="startDate"
                                type="text"
                                id="startDate"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        {...field}
                                        className="form-control specialBorder borderFix "
                                        autoComplete="off"
                                        id="startDate"
                                        name='startDate'
                                        onChange={(date) => handleOnChange(date, 'startDate')}
                                        value={startDate}
                                        clearIcon={null}
                                        maxDate={new Date()}
                                    />
                                )}
                            />
                        </div>
                        <div className='col-12 p-2'>
                        <label htmlFor="card_number">Start date</label>
                            <Controller
                                name="endDate"
                                type="text"
                                id="endDate"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        {...field}
                                        className="form-control specialBorder borderFix "
                                        autoComplete="off"
                                        id="endDate"
                                        name='endDate'
                                        onChange={(date) => handleOnChange(date, 'endDate')}
                                        value={endDate}
                                        clearIcon={null}
                                        minDate={startDate}
                                        maxDate={new Date()}
                                    />
                                )}
                            />
                        </div>
                        <div className='col-12 p-2 d-flex justify-content-end'>
                            <button 
                                name="download"
                                type="submit"
                                disabled={isDownloading}
                                className="btn btn-primary">
                                    Download
                            </button>
                        </div>
                        </form>
                    </div>
                    
                    
                </div>
                
            </div>
        </div>
	)
}

Report.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    notify: PropTypes.func,
    isFetching: PropTypes.bool,
    partnerDetails: PropTypes.object,
}
  
Report.defaulProps = {
    dispatch: noop,
    loader: false,
    notify: noop,
    isFetching: true,
    partnerDetails: EMPTY_OBJECT,
}

function mapStateToProps({ singInAndSingUp, userDetailsReducer }) {
    return {
        loader: singInAndSingUp?.loader,
        isFetching: userDetailsReducer?.isFetching,
    }
}
export default connect(mapStateToProps)(Report)

