import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { ERROR_MESSAGE } from '../../constants/errorMessage'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/globalConstants'
import REGEX_PATTERN from '../../constants/regex'
import URL from '../../constants/urls'
import { userSingIn } from '../../redux/actions/singinAndSingUp'
import { Circles } from 'react-loader-spinner'
import { useState } from 'react'
import { convertJSONtoFormData, encryptMailId } from '../../utils/helper'
import { exportReport } from '../../redux/actions/export&Download'
import { InputGroup } from 'react-bootstrap'
import { useEffect } from 'react'

function Login(props) {
    const {dispatch, notify, setIsLoggedIn, isLoggedIn} = props
    const navigate = useNavigate()
    const { register, handleSubmit, formState: { errors }, reset } = useForm()
    const [loader, setLoader] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    const navigateToSignUp = () => {
        navigate(URL?.SIGNUP)
    }
    const navigateToResetPassword = () => {
        navigate(URL?.FORGOTPASSWORD)
    }

    useEffect(() => {
        const userData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : EMPTY_OBJECT
        if(userData?.examAttended == 1){
            navigate(URL?.THANKYOU)
        }else if(userData?.userid){
            navigate(URL?.EXAM)
        }

    }, [EMPTY_ARRAY, isLoggedIn])

    const handleLogin = (data) => {
        setLoader(true)
        const payload = {...data, apicall: 'adminlogin'}
        const formData = convertJSONtoFormData(payload)
        if(!loader){
            dispatch(userSingIn(formData)).then((res) => {
                setLoader(false)
                if(res && !res.error){
                    setIsLoggedIn(true)
                    localStorage.setItem('user', JSON.stringify(res?.user))
                    notify('success', res?.message ? res?.message : 'LoggedIn Successfully!')
                        navigate(URL?.USERS)
                }else{
                    setIsLoggedIn(false)
                    notify('error', res?.message ? res?.message : 'Something went Wrong!')
                }
            }).catch((err) =>{
                setIsLoggedIn(false)
                setLoader(false)
                notify('error', err?.message ? err?.message : 'Error in Login In!')
            })
        }
    }

    // const handleExport = () => {
        
    //     const payload = {apicall: 'exportreport', username: 'hemant'}
    //     const formData = convertJSONtoFormData(payload)
    //     if(!loader){
    //         dispatch(exportReport(formData)).then((res) => {
    //             if(res){
    //                 notify('success', res?.message ? res?.message : 'File Downloaded!')
    //             }else{
    //                 notify('error', res?.message ? res?.message : 'Something went Wrong!')
    //             }
    //         }).catch((err) =>{
                
    //             notify('error', err?.message ? err?.message : 'Something went Wrong!')
    //         })
    //     }
    // }
    
    const togglePasswordVisiblity = () => {
        setShowPassword(!showPassword)
    }
    
	return (
        <div>
		<div className='d-flex flex-column align-items-center m-5'>
            <div className='col-12 col-sm-6 border rounded p-3'>
                <h3 className='fw-bold'>Login</h3>
                <form autoComplete='on' className='d-flex row' onSubmit={handleSubmit(handleLogin)}>
                    <div className='col-sm-12 col-12'>    
                    
                        <label className='fw-normal mt-2 mb-2' htmlFor='username'>Username or Email <span className='text-danger'>*</span></label>
                        <input 
                            {...register('username', {
                                required: { value: true, message: ERROR_MESSAGE?.REQ_ERROR_MSG },
                            })}
                            className='form-control mt-2 mb-2'
                            type='text'
                            name='username'
                            id='username'
                            placeholder='Email or username'
                            autoComplete='off'
                        />
                        {(errors?.username) ? <span className='text-danger'>{errors?.username?.message}</span> : null}
                    </div>
                    <div className='col-sm-12 col-12'>    
                        <label className='fw-normal mt-2 mb-2'  htmlFor='password'>Password <span className='text-danger'>*</span></label>
                        <InputGroup className="p-0 form-control mt-2 mb-2 d-flex align-items-center">
                        <input 
                            {...register('password', {
                                required: { value: true, message: ERROR_MESSAGE?.REQ_ERROR_MSG },
                                pattern: {
                                    value: REGEX_PATTERN.PASSWORD_FORMAT,
                                    message: ERROR_MESSAGE.PASSWORD_MESSAGE,
                                },
                            })}
                            className='form-control m-0 border-0'
                            type={showPassword ? 'text' : 'password'}
                            name='password'
                            id='password'
                            placeholder='Password'
                        />
                        <i className={`${showPassword ? 'fas fa-eye-slash ' : 'fas fa-eye '} loginPassIcon me-1 ms-1`} onClick={(e) => togglePasswordVisiblity(e, 'password')}></i>
                        </InputGroup>
                        {(errors?.password) ? <span className='text-danger'>{errors?.password?.message}</span> : null}
                    </div>
                    <div className='col-sm-12 col-12 mt-2 mb-2 '>    
                        <div className='d-flex flex-row align-items-center'>
                            <input 
                                {...register('rememberme')}
                                className='me-2'
                                type='checkbox'
                                name='rememberme'
                                id='rememberme'  
                            /><label className='fw-normal' htmlFor='rememberme'>Remember Me</label>
                        </div>
                    </div>
                    <div className='col-sm-12 col-12 mt-2 mb-2 '>    
                        <button 
                            type='submit'
                            name='submit'
                            className='btn btn-primary text-white'
                            disabled={loader}
                            >
                        <div className='d-flex justify-content-center align-items-center'>
                            <span>Login </span>  <span className='ms-2'>
                                {(loader) ? <Circles
                                    height="20"
                                    width="20"
                                    style={{ display: 'inline-block' }}
                                    color="#ffffff"
                                    ariaLabel="circles-loading"
                                    visible={true}
                                /> : null}
                            </span>
                        </div>
                        </button>
                    </div>
                                    {/* <span onClick={() => handleExport()}>Download</span> */}
                    <div className='col-sm-12 col-12 mt-2 mb-2'>    
                        <span className='text-primary'>
                            <span className='pointer' onClick={() => navigateToResetPassword()}>Lost your password?</span>
                        </span>
                    </div>

                </form>
            </div>
            
		</div>
        </div>
	)
}

Login.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    notify: PropTypes.func,
    isFetching: PropTypes.bool,
    partnerDetails: PropTypes.object,
    setIsLoggedIn: PropTypes.func,
    isLoggedIn: PropTypes.bool,
}
  
Login.defaulProps = {
    dispatch: noop,
    loader: false,
    notify: noop,
    isFetching: true,
    partnerDetails: EMPTY_OBJECT,
    setIsLoggedIn: noop,
    isLoggedIn: false,
}

function mapStateToProps({ singInAndSingUp, userDetailsReducer }) {
    return {
        loader: singInAndSingUp?.loader,
        isFetching: userDetailsReducer?.isFetching,
    }
}
export default connect(mapStateToProps)(Login)

