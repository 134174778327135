import CONSTANTS from '../constants'
import { postRequest } from '../../services'
import { getConfigDetails } from '../../services/config'
import { EMPTY_OBJECT } from '../../constants/globalConstants'


// sing_in
export const getUsers = (creds) => (dispatch) => {
  dispatch({ type: CONSTANTS.GET_USERS_REQUEST })
  return postRequest(getConfigDetails().baseURL, creds).then((res) => {
    dispatch({ type: CONSTANTS.GET_USERS_SUCCESS, data: res })
    return res
  }).catch((err) => {
    dispatch({ type: CONSTANTS.GET_USERS_FAILURE })
    return err
  })
}
